import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/LitreryClub/2023/Literacyday/1.jpg';
import p2 from 'assests/Photos/Clubs/LitreryClub/2023/Literacyday/2.jpg';
import p3 from 'assests/Photos/Clubs/LitreryClub/2023/Literacyday/3.jpg';
import p4 from 'assests/Photos/Clubs/LitreryClub/2023/Literacyday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';

const LiteracyDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        }, 
        
        
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                International Literacy Day
                                </Typography>
                              
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 4 &  Date: 08 SEPTEMBER 2023
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Education is the most powerful weapon you can use to change the world.” Nelson Mandela."
                                <br />
                            </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                International Literacy Day was observed on Friday, 08 September 2023 to spread awareness about the importance of Literacy among the masses. The students of Class 4 participated in the formation of the word READ and read silently for half an hour. During the morning assembly, they entertained the audience with a beautiful song that highlighted the importance of reading. On this occasion, the Principal emphasised the importance of reading and advised the students to cultivate a habit of reading newspapers every day to learn about the current affairs.
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Today a Reader, Tomorrow a Leader - International Literacy Day 2023"
                                <br />
                            </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                                    <Sidebar2023/>
                        </Box>
                        <Box marginBottom={4}>
                            <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default LiteracyDay2023;